import React from 'react';

const MidPageCourseCTA = () => {
  return (
    <section className="py-16 bg-[#1C3738] text-[#F4FFF8] relative overflow-hidden">
      {/* Background pattern */}
      <div className="absolute inset-0 opacity-10">
        <div className="w-full h-full" style={{
          backgroundImage: 'radial-gradient(#F4FFF8 1px, transparent 1px)',
          backgroundSize: '20px 20px'
        }}></div>
      </div>

      <div className="container mx-auto px-4 relative z-10">
        <p className="text-center text-[#8BAAAD] mb-2">Inscrivez vous dès maintenant</p>
        <h2 className="text-4xl font-bold text-center mb-8">GenAI et Automatisation</h2>

        <div className="bg-[#000F08] rounded-lg p-6 max-w-2xl mx-auto mb-6">
          <div className="grid grid-cols-2 md:grid-cols-3 gap-3 text-center">
            <div>
              <p className="text-[#8BAAAD] text-sm uppercase">Coût</p>
              <p className="font-bold">299 CHF</p>
            </div>
            <div>
              <p className="text-[#8BAAAD] text-sm uppercase">Dates</p>
              <p className="font-bold">1 Oct 2024</p>
            </div>
            <div>
              <p className="text-[#8BAAAD] text-sm uppercase">Dernière inscription</p>
              <p className="font-bold">30 Sept 2024</p>
            </div>
          </div>
        </div>

{/*         <div className="text-center mb-6">
          <a href="#" className="text-[#8BAAAD] hover:underline mr-4">Obtenir un remboursement</a>
          <a href="#" className="text-[#8BAAAD] hover:underline">Achats en gros</a>
        </div>
 */}
        <div className="text-center">
          <a href="https://www.formationgenai.com/achat" target="_blank" rel="noopener noreferrer" className="bg-[#4D4847] hover:bg-[#8BAAAD] text-[#F4FFF8] font-bold py-3 px-8 rounded-full transition duration-300">
            S'inscrire maintenant
          </a>
        </div>
      </div>
    </section>
  );
};

export default MidPageCourseCTA;