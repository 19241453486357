import React, { useState } from 'react';
import { Clock, Tag, Menu, X } from 'lucide-react';
import comfyUIImage from './images/comfyUI-workflow.webp';
import martinArnouxImage from './images/martin-arnoux.png';
import elliotVaucherImage from './images/elliot-vaucher.png';
import independantImage from './images/independant.jpg'
import consultantImage from './images/consultant.jpg'
import ceoImage from './images/ceo.jpg'
import FAQSection from './FAQSection';
import CourseInclusionsSection from './CourseInclusionSection';
import CourseSyllabusSection from './CourseSyllabusSection';
import BeyondChatGPTSection from './BeyondChatGPTSection';
import MidPageCourseCTA from './MidPageCourseCTA';
import ToolsAndTechnologies from './ToolsAndTechnologies';
import ExpertTestimonialSection from './ExpertTestimonialSection';

const LandingPage = () => {
  const [startDate, setStartDate] = useState('01 octobre 2024');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <div className="min-h-screen bg-[#000F08] text-[#FFFFFF]">
      {/* Improved Mobile Header */}
      <header className="bg-[#1C3738] text-[#FFFFFF] p-4 fixed top-0 left-0 right-0 z-50">
        <div className="container mx-auto flex justify-between items-center">
          <h1 className="text-xl font-bold">IA Générative</h1>
          <button onClick={toggleMenu} className="md:hidden">
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
          <nav className={`${isMenuOpen ? 'flex' : 'hidden'} md:flex flex-col md:flex-row absolute md:relative top-full left-0 right-0 bg-[#1C3738] md:bg-transparent p-4 md:p-0 space-y-4 md:space-y-0 md:space-x-4`}>
            <a href="#modules" className="hover:text-[#8BAAAD] transition-colors">Aperçu</a>
            <a href="#FAQ" className="hover:text-[#8BAAAD] transition-colors">FAQ</a>
            <a 
              href="mailto:info@ritsl.ch?subject=Demande de contact - Formation IA Générative et Automatisation"
              className="btn btn-outline btn-sm text-[#FFFFFF] border-[#FFFFFF] hover:bg-[#FFFFFF] hover:text-[#000F08]"
            >
              Nous contacter
            </a>
            <a 
              href="https://www.formationgenai.com/achat" 
              className="btn btn-sm bg-[#8BAAAD] text-[#000F08] hover:bg-[#FFFFFF]" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              S'inscrire maintenant
            </a>
          </nav>
        </div>
      </header>

      {/* Add padding to the top to account for the fixed header */}
      <div className="pt-16">
        {/* Hero Section */}
        <section className="bg-[#1C3738] text-[#FFFFFF] py-16">
          <div className="container mx-auto px-4 flex flex-col md:flex-row items-center">
            <div className="w-full md:w-1/2 mb-8 md:mb-0">
              <h1 className="text-4xl sm:text-5xl font-bold mb-4">IA Générative et Automatisation</h1>
              <p className="text-xl sm:text-2xl mb-8">Maîtrisez tout le potentiel de l'intelligence artificielle générative</p>
              <div className="space-y-4 sm:space-y-0 sm:space-x-4 flex flex-col sm:flex-row">
                <a href="https://www.formationgenai.com/achat" className="btn btn-primary bg-[#8BAAAD] text-[#000F08] hover:bg-[#FFFFFF] w-full sm:w-auto" target="_blank" rel="noopener noreferrer">S'inscrire maintenant</a>
                <button 
                  className="btn btn-outline text-[#FFFFFF] border-[#FFFFFF] hover:bg-[#FFFFFF] hover:text-[#000F08] w-full sm:w-auto"
                  onClick={() => {
                    const element = document.getElementById('modules');
                    if (element) {
                      element.scrollIntoView({ behavior: 'smooth' });
                    }
                  }}
                >
                  En savoir plus sur cette formation
                </button>
              </div>
            </div>
            <div className="w-full md:w-1/2">
              <div className="w-full">
                <img src={comfyUIImage} alt="Interface de flux de travail ComfyUI" className="w-full h-auto" />
              </div>
            </div>
          </div>
        </section>

        {/* Info Bar */}
        <section className="bg-[#8BAAAD] text-[#000F08] py-4">
          <div className="container mx-auto px-4">
            <div className="flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0 sm:space-x-8">
              <div className="flex items-center">
                <Clock className="mr-2" size={20} />
                <span>En ligne</span>
              </div>
              <div className="flex items-center">
                <Clock className="mr-2" size={20} />
                <span>9 modules à votre rythme</span>
              </div>
              <div className="flex items-center">
                <Tag className="mr-2" size={20} />
                <span>CHF 299</span>
              </div>
              <div className="flex items-center">
                <Clock className="mr-2" size={20} />
                <span>Prochaine date limite d'inscription : 30 septembre 2024</span>
              </div>
            </div>
          </div>
        </section>

        {/* Main Content */}
        <main className="container mx-auto py-16 px-4">
          <div className="flex flex-col lg:flex-row">
            <div className="w-full lg:w-2/3 lg:pr-8 mb-8 lg:mb-0">
              <section className="mb-16">
                <h2 className="text-3xl font-bold mb-4">À propos de cette formation</h2>
                <p className="mb-4">À l'issue de ce cours 100% online vous maîtriserez tous les secrets de l'IA générative multimodale (texte, image, vidéo, son), ainsi que les outils et méthodes low-code / no-code pour l'intégrer facilement à vos processus de travail.</p>
                <p className="mb-4">Quel que soit votre domaine professionnel, vous serez capable d'appliquer l'intelligence artificielle générative à vos problématiques métiers spécifiques.</p>
              </section>

              <section className="mb-16">
                <h2 className="text-3xl font-bold mb-4">Ce que vous apprendrez</h2>
                <ul className="list-disc list-inside space-y-2">
                  <li>Passer d'une idée à un prototype en un temps record</li>
                  <li>Comprendre les briques essentielles pour automatiser des processus métiers avec l'IA Générative</li>
                  <li>Connaître les principaux outils existants d' automatisation et d'IA Générative avancés</li>
                  <li>Apprendre à produire, des images, de l'audio, des sites webs à partir de texte </li>
                  <li>Développer et gérer des agents et assistants personalisés</li>
                  <li>Connaître les GPUs et les ressources matérielles de génération IA</li>
                  <li>Adopter les bonnes pratiques en matière d'éthique et de privacité des données IA</li>
                </ul>
              </section>

              <section className="mb-16">
                <h2 className="text-3xl font-bold mb-4">Aperçu du programme</h2>
                <p className="mb-4">Toutes les sessions en direct sont enregistrées et seront disponibles pour être visionnées à nouveau sur la plateforme d'apprentissage.</p>
                <button 
                  className="btn btn-primary bg-[#8BAAAD] text-[#000F08] hover:bg-[#FFFFFF] mb-4 w-full sm:w-auto"
                  onClick={() => {
                    const element = document.getElementById('modules');
                    if (element) {
                      element.scrollIntoView({ behavior: 'smooth' });
                    }
                  }}
                >
                  Voir les détails du programme ci-dessous
                </button>
              </section>

              {/* Trainers Section */}
              <section id="formateurs" className="mb-16">
                <h2 className="text-3xl font-bold mb-8">Les formateurs</h2>
                <div className="flex flex-col sm:flex-row justify-between">
                  <div className="text-center w-full sm:w-1/2 mb-8 sm:mb-0">
                    <div className="w-48 h-48 rounded-full overflow-hidden mx-auto mb-4">
                      <img src={martinArnouxImage} alt="Martin Arnoux" className="w-full h-auto" />
                    </div>
                    <h3 className="text-xl font-semibold">Martin Arnoux</h3>
                    <p>Ingénieur diplômé EPFL, spécialisé dans l'innovation technologique.</p>
                  </div>
                  <div className="text-center w-full sm:w-1/2">
                    <div className="w-48 h-48 rounded-full overflow-hidden mx-auto mb-4">
                      <img src={elliotVaucherImage} alt="Elliot Vaucher" className="w-full h-auto" />
                    </div>
                    <h3 className="text-xl font-semibold">Elliot Vaucher</h3>
                    <p>Expert en formation, marketing et intelligence artificielle.</p>
                  </div>
                </div>
              </section>
            </div>

            <div className="w-full lg:w-1/3">
              <div className="sticky top-20">
                <div className="card bg-[#1C3738] shadow-xl p-6">
                  <h2 className="text-2xl font-bold mb-4">EN BREF</h2>
                  <h3 className="text-xl font-bold mb-2">Formation IA Générative</h3>
                  <p className="mb-4">Apprenez à utiliser l'intelligence artificielle générative pour booster votre efficacité</p>
                  <div className="mb-4">
                    <h4 className="font-bold">Durée</h4>
                    <div className="flex items-center">
                      <Clock className="mr-2" size={20} />
                      <span>9 modules de cours à votre ryhtme</span>
                    </div>
                  </div>
                  <div className="mb-4">
                    <h4 className="font-bold">Prix</h4>
                    <div className="flex items-center">
                      <Tag className="mr-2" size={20} />
                      <span className="font-bold">CHF 299</span>
                      <span className="ml-2 line-through">au lieu de CHF 499</span>
                    </div>
                  </div>
                  <div className="bg-[#8BAAAD] text-[#000F08] p-2 rounded mb-4 text-center">
                    Offre de lancement jusqu'au 30 septembre 2024
                  </div>
                  <div className="mb-4">
                    <h4 className="font-bold mb-2">Choisissez une date de début</h4>
                    <select 
                      className="select select-bordered w-full bg-[#1C3738] text-[#FFFFFF]"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    >
                      <option>1 octobre</option>
                    </select>
                  </div>
                  <a href="https://www.formationgenai.com/achat" className="btn btn-primary bg-[#8BAAAD] text-[#000F08] hover:bg-[#FFFFFF] w-full" target="_blank" rel="noopener noreferrer">S'inscrire maintenant</a>
                </div>
              </div>
            </div>
          </div>
        </main>

        <BeyondChatGPTSection />

        {/* Target Audience Section */}
        <section className="py-16 bg-[#1C3738]">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-bold mb-12 text-center">À qui s'adresse ce cours IA ?</h2>
            <div className="flex flex-wrap justify-center gap-10">
              <div className="text-center flex-1 min-w-[300px] max-w-[340px]">
                <div className="w-48 h-48 rounded-full overflow-hidden mx-auto mb-4">
                  <div className="bg-[#000F08] w-full h-full flex items-center justify-center">
                    <img src={independantImage} alt="indépendant" className="w-full h-auto" />
                  </div>
                </div>
                <h3 className="text-xl font-semibold mb-2">Indépendants·es</h3>
                <p>Boostez votre productivité et gérez tous les aspects de votre business en solo.</p>
              </div>
              <div className="text-center flex-1 min-w-[300px] max-w-[340px]">
                <div className="w-48 h-48 rounded-full overflow-hidden mx-auto mb-4">
                  <div className="bg-[#000F08] w-full h-full flex items-center justify-center">
                    <img src={consultantImage} alt="consultant" className="w-full h-auto" />
                  </div>
                </div>
                <h3 className="text-xl font-semibold mb-2">Consultants·es</h3>
                <p>Démarquez-vous avec des compétences de pointe en IA générative et automatisation.</p>
              </div>
              <div className="text-center flex-1 min-w-[300px] max-w-[340px]">
                <div className="w-48 h-48 rounded-full overflow-hidden mx-auto mb-4">
                  <div className="bg-[#000F08] w-full h-full flex items-center justify-center">
                    <img src={ceoImage} alt="CEO" className="w-full h-auto" />
                  </div>
                </div>
                <h3 className="text-xl font-semibold mb-2">CEO et gestionnaires</h3>
                <p>Transformez votre entreprise et prenez une longueur d'avance avec l'IA.</p>
              </div>
            </div>
          </div>
        </section>

        <CourseSyllabusSection />

        <MidPageCourseCTA />

        <CourseInclusionsSection />

        <ExpertTestimonialSection />

        <ToolsAndTechnologies />

        <FAQSection />

        {/* Footer */}
        <footer className="bg-[#F4FFF8] text-[#000000] py-8">
          <div className="container mx-auto">
            <div className="flex flex-wrap justify-between">
              <div className="w-full md:w-1/3 mb-4 md:mb-0">
                <h3 className="text-xl font-bold mb-4">Contactez-nous</h3>
                <p>Email: info@ritsl.ch</p>
                <p>Téléphone: +41 21 217 32 37</p>
              </div>
              <div className="w-full md:w-1/3 mb-4 md:mb-0">
                <h3 className="text-xl font-bold mb-4">Liens rapides</h3>
                <ul>
                  <li><a href="#formateurs" className="hover:underline">À propos de nous</a></li>
                  <li><a href="https://www.ritsl.ch/contact" className="hover:underline">Contact</a></li>
                </ul>
              </div>
              <div className="w-full md:w-1/3">
                <h3 className="text-xl font-bold mb-4">Suivez-nous</h3>
                <a href="https://linktr.ee/aconit" className="hover:text-[#8BAAAD]" target="_blank" rel="noopener noreferrer">
                  Linktree
                </a>
              </div>
            </div>
            <div className="mt-8 text-center">
              <p>&copy; 2024 RITSL. Tous droits réservés.</p>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default LandingPage;