import React from 'react';
import { BookOpen, Clock, Briefcase, Users, Infinity, Headphones, MessageCircle, Award } from 'lucide-react';

const CourseInclusionsSection = () => {
  const inclusions = [
    { icon: <Clock className="w-6 h-6" />, text: "9 modules de cours 100% online" },
    { icon: <BookOpen className="w-6 h-6" />, text: "43 chapitres" },
    { icon: <Briefcase className="w-6 h-6" />, text: "Exemples théoriques et cas pratiques appliqués" },
    { icon: <Users className="w-6 h-6" />, text: "Accès à une communauté d'apprenants via Discord" },
    { icon: <Award className="w-6 h-6" />, text: "Recommandation de logiciels et outils existants" },
    { icon: <Infinity className="w-6 h-6" />, text: "Accès à vie aux supports de cours" },
    { icon: <Headphones className="w-6 h-6" />, text: "Accès direct à l'instructeur" },
    { icon: <MessageCircle className="w-6 h-6" />, text: "Retours guidés et réflexion" },
  ];

  return (
    <section className="py-16 bg-[#000F08]">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-8 text-center text-[#F4FFF8]">Ce cours comprend</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-4">
            {inclusions.slice(0, Math.ceil(inclusions.length / 2)).map((item, index) => (
              <div key={index} className="flex items-center space-x-4 bg-[#1C3738] p-4 rounded-lg">
                <div className="text-[#8BAAAD] flex-shrink-0">{item.icon}</div>
                <p className="text-[#F4FFF8]">{item.text}</p>
              </div>
            ))}
          </div>
          <div className="space-y-4">
            {inclusions.slice(Math.ceil(inclusions.length / 2)).map((item, index) => (
              <div key={index} className="flex items-center space-x-4 bg-[#1C3738] p-4 rounded-lg">
                <div className="text-[#8BAAAD] flex-shrink-0">{item.icon}</div>
                <p className="text-[#F4FFF8]">{item.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CourseInclusionsSection;