import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

const CourseSyllabusSection = () => {
  const [expandedWeeks, setExpandedWeeks] = useState([]);
  const syllabus = [
    {
      week: 1,
      dateRange: "Module 1: Intro",
      events: [
        { date: "Chap 1", title: "Intro générale au cours", time: "Concepts d'IA, applications métiers, outils d'automatisation" },
        { date: "Chap 2", title: "Prompt engineering 101 (texte)", time: "OpenAI, modèles GPT" },
        { date: "Chap 3", title: "Prompt engineering 101 (image)", time: "DALL-E, outils de traitement d'image" },
        { date: "Chap 4", title: "Concept de Indie Hacking", time: "Plateformes indépendantes, GitHub" },
        { date: "Chap 5", title: "Création de MVPs, prototypes, POCs, etc.", time: "Outils de prototypage, Sketch, Figma" }
      ]
    },
    {
      week: 2,
      dateRange: "Module 2: Data / Inputs",
      events: [
        { date: "Chap 1", title: "Lexique et bases conceptuelles", time: "Terminologie des données, outils de gestion de données de base" },
        { date: "Chap 2", title: "Intro à la logique des inputs", time: "Gestion des inputs : processeurs d'image, audio, vidéo" },
        { date: "Chap 3", title: "Exemples de cas concrets", time: "Applications réelles : outils de web scraping, logiciels OCR" },
        { date: "Chap 4", title: "Use case Partykit: data pre-processing", time: "ChatGPT, Semrush, Airtable" }
      ]
    },
    {
      week: 3,
      dateRange: "Module 3: Database",
      events: [
        { date: "Chap 1", title: "Lexique et bases conceptuelles", time: "Bases de données SQL, NoSQL" },
        { date: "Chap 2", title: "Intro à la logique d'une database dynamique", time: "Airtable, Firebase" },
        { date: "Chap 3", title: "Exemples de cas concrets", time: "Airtable, exemples d'intégration de bases de données" },
        { date: "Chap 4", title: "Use case Partykit: Airtable automations", time: "Airtable, workflows automatisés" }
      ]
    },
    {
      week: 4,
      dateRange: "Module 4: Automatisation No-Code/Low-Code",
      events: [
        { date: "Chap 1", title: "Lexique et bases conceptuelles", time: "Aperçu des plateformes No-code/Low-code" },
        { date: "Chap 2", title: "Intro à la logique des automations", time: "Zapier, Make, Airtable" },
        { date: "Chap 3", title: "Exemples de cas concrets avec Make", time: "Make, gestion automatisée des tâches" },
        { date: "Chap 4", title: "Use case Partykit: Make", time: "Make, automatisations spécifiques de projets" }
      ]
    },
    {
      week: 5,
      dateRange: "Module 5: Générateurs IA",
      events: [
        { date: "Chap 1", title: "Lexique et bases conceptuelles", time: "Outils IA généraux, compréhension des modèles" },
        { date: "Chap 2", title: "Intro à la logique des générateurs", time: "GPT, DALL-E" },
        { date: "Chap 3", title: "Prompt engineering avancé (texte)", time: "Modèles GPT avancés, techniques de personnalisation" },
        { date: "Chap 4", title: "Prompt engineering avancé (image)", time: "DALL-E, techniques avancées de génération d'image" },
        { date: "Chap 5", title: "RAG, Vector-store, Assistants", time: "OpenAI assistant, RAG, Flowise" },
        { date: "Chap 6", title: "Exemples de cas concrets", time: "GPT, DALL-E, applications réelles" },
        { date: "Chap 7", title: "Use case Partykit", time: "ChatGPT, DALL-E, intégration dans les workflows" }
      ]
    },
    {
        week: 6,
        dateRange: "Module 6: GPUs et puissance de calcul",
        events: [
          { date: "Chap 1", title: "Lexique et bases conceptuelles", time: "Introduction aux GPUs, CUDA" },
          { date: "Chap 2", title: "Intro à la logique des GPU", time: "NVIDIA, AMD" },
          { date: "Chap 3", title: "Installation", time: "Procédures d'installation des logiciels" },
          { date: "Chap 4", title: "Optimisation des coûts", time: "Stratégies de gestion des coûts, plateformes cloud" },
          { date: "Chap 5", title: "Installation de modèles open-source", time: "GitHub, dépôts de modèles" },
          { date: "Chap 6", title: "ComfyUI", time: "Cadres UI, intégration" },
          { date: "Chap 7", title: "Exemples de cas concrets", time: "Traitement par lots, upscaling d'images" },
          { date: "Chap 8", title: "Use case Partykit ComfyUI, Parenthèse ChatGPT pour créer scripts python", time: "ComfyUI, ChatGPT, script Python" }
        ]
      },
      {
        week: 7,
        dateRange: "Module 7: Frontend / Outputs / Produit fini",
        events: [
          { date: "Chap 1", title: "Lexique et bases conceptuelles", time: "Principes de web design, IA dans le design" },
          { date: "Chap 2", title: "Intro à la logique du webdesign AI", time: "Outils de design pilotés par l'IA comme Relume, Webflow" },
          { date: "Chap 3", title: "Exemples de cas concrets", time: "Exemples réels : Gamma, outils web IA" },
          { date: "Chap 4", title: "Use case Partykit: site web + output PDF", time: "Webflow, outils de génération de PDF" }
        ]
      },
      {
        week: 8,
        dateRange: "Module 8: Sécurité et privacité des données",
        events: [
          { date: "Chap 1", title: "Lexique et bases conceptuelles", time: "Bases de la sécurité des données, outils de chiffrement" },
          { date: "Chap 2", title: "Intro à la logique de la privacité des données", time: "Outils axés sur la confidentialité, stockage sécurisé des données" },
          { date: "Chap 3", title: "Utilisation de modèles open-source, stockage des données, version web chat VS api playground", time: "Modèles open-source, solutions de stockage des données" },
          { date: "Chap 4", title: "Best security practices", time: "Meilleures pratiques de sécurité, outils de cybersécurité" },
          { date: "Chap 5", title: "Use case Partykit", time: "Mise en œuvre des mesures de sécurité dans Partykit" }
        ]
      },
      {
        week: 9,
        dateRange: "Module 9: Conclusion et changements de paradigmes entreprenariaux",
        events: [
          { date: "Chap 1", title: "Logique business idée - prototype", time: "Idéation business aux outils de prototypage" },
          { date: "Chap 2", title: "Transformation de la logique business", time: "Stratégies de transformation des modèles économiques" }
        ]
      }
    ];

  
  const toggleWeek = (week) => {
    setExpandedWeeks(prev => 
      prev.includes(week) ? prev.filter(w => w !== week) : [...prev, week]
    );
  };

  const isWeekExpanded = (week) => expandedWeeks.includes(week);

  return (
    <section id="modules" className="py-16 bg-[#F4FFF8]">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-4 text-[#000F08]">Modules du cours</h2>
        <button 
          className="text-green-600 hover:underline mb-8"
          onClick={() => setExpandedWeeks(syllabus.map(w => w.week))}
        >
          Ouvrir tous les modules
        </button>
        {syllabus.map((week) => (
          <div key={week.week} className="mb-8 border-t border-gray-200 pt-4">
            <div 
              className="flex justify-between items-center cursor-pointer"
              onClick={() => toggleWeek(week.week)}
            >
              <h3 className="font-bold text-[#000F08]">
                Module {week.week} <span className="font-normal text-gray-500 ml-2">{week.dateRange}</span>
              </h3>
              {isWeekExpanded(week.week) ? <ChevronUp /> : <ChevronDown />}
            </div>
            {isWeekExpanded(week.week) && (
              <div className="mt-4 space-y-4">
                {week.events.map((event, index) => (
                  <div key={index} className="bg-white p-4 rounded-lg shadow">
                    <div className="flex items-center mb-2">
                      <div className="bg-[#1C3738] text-white text-xs font-bold px-2 py-1 rounded mr-2">
                        {event.date}
                      </div>
                      <h4 className="font-bold text-[#000F08]">{event.title}</h4>
                    </div>
                    <p className="text-gray-600 text-sm">{event.time}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default CourseSyllabusSection;