import React from 'react';
import airtableImg from './images/airtable.webp';
import lightningImg from './images/lightningai.webp';
import makeImg from './images/make.webp';
import metaImg from './images/meta.webp';
import openaiImg from './images/openai.webp';
import semrushImg from './images/semrush.webp';
import softrImg from './images/softr.webp';
import webflowImg from './images/webflow.webp';

const toolImages = {
  Airtable: airtableImg,
  Lightning: lightningImg,
  Make: makeImg,
  Meta: metaImg,
  OpenAI: openaiImg,
  SEMRUSH: semrushImg,
  Softr: softrImg,
  Webflow: webflowImg,
};

const ToolsAndTechnologies = () => {
  return (
    <section className="py-16 bg-[#1C3738]">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold mb-8 text-center text-[#FFFFFF]">Outils et technologies (non exhaustif)</h2>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-8">
          {Object.entries(toolImages).map(([tool, imageSrc]) => (
            <div key={tool} className="text-center flex flex-col items-center">
              <div className="h-24 w-full mb-4 flex items-center justify-center">
                <img src={imageSrc} alt={tool} className="max-h-full w-auto object-contain" />
              </div>
              <p className="font-semibold text-[#FFFFFF]">{tool}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ToolsAndTechnologies;