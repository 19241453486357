import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

const BeyondChatGPTSection = () => {
  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (index) => {
    setOpenSection(openSection === index ? null : index);
  };

  const sections = [
    {
      title: "Bien plus qu'un cours de prompt engineering",
      content: "Notre formation vous offre une compréhension des concepts essentiels autour de l'IA générative, tels que modèles, Lora, LLM, checkpoints, control net, prompts, fine tuning, inpainting, outpainting, agent, RAG, assistant, vector store, token, GPU. Tous ces concepts spécifiques à l'IA Générative multimodale seront appliqués à des cas concrets pour designer des systèmes automatiques intelligents et spécifiques à des problématiques métiers.",
      //image: martinArnoux// Replace with actual path
    },
    {
      title: "Des exemples d'applications et d'automatisations concrètes",
      content: "Notre but est d'offrir un accompagnement qui vous permette d'automatiser des tâches concrètes, comme la création d'un blog optimisé SEO avec des images et des textes 100% générés par IA.",
      //image: "/path-to-automation-image.jpg" // Replace with actual path
    }
  ];

  return (
    <section className="py-16 bg-[#000F08] text-[#F4FFF8]">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-2 text-center">Marre des cours de prompting ChatGPT ?</h2>
        <p className="text-center mb-8">La majorité des formations IA se limitent à vous apprendre comment structurer un prompt dans ChatGPT.</p>
        
        <div className="space-y-4">
          {sections.map((section, index) => (
            <div key={index} className="bg-[#1C3738] rounded-lg overflow-hidden">
              <button
                className="w-full p-4 text-left font-bold flex justify-between items-center"
                onClick={() => toggleSection(index)}
              >
                {section.title}
                {openSection === index ? <ChevronUp /> : <ChevronDown />}
              </button>
              {openSection === index && (
                <div className="p-4 bg-[#4D4847]">
                  <div className="md:flex">
                    <div className="md:w-1/2 pr-4">
                      <p>{section.content}</p>
                    </div>
{/*                     <div className="md:w-1/2 mt-4 md:mt-0">
                      <img src={section.image} alt={section.title} className="w-full h-auto rounded-lg" />
                    </div> */}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BeyondChatGPTSection;