import React from 'react';

const FAQSection = () => {
  const faqItems = [
    {
      question: "Est-ce que j'ai besoin de connaissances préalables en intelligence artificielle pour suivre le cours?",
      answer: "Non. Ce cours a été conçu pour s'adresser à des personnes de tous niveaux."
    },
    {
      question: "Qu'est-ce qui est inclu dans le cours?",
      answer: (
        <ul className="list-disc pl-5">
          <li>9 modules de cours</li>
          <li>43 chapitres</li>
          <li>Accès direct aux formateurs</li>
          <li>Scripts du cours accessibles à vie</li>
          <li>Accès au Discord et à la communauté</li>
          <li>Support 24/7</li>
          <li>Mises à jour fréquentes du cours</li>
        </ul>
      )
    },
    {
      question: "Quand le cours sera-t-il publié?",
      answer: "Le cours sur l'intelligence artificielle générative et l'automatisation débutera le 1er octobre 2024."
    },
    {
      question: "Comment faire si j'ai des questions pendant le cours?",
      answer: "L'accès au canal Discord vous permettra d'interagir directement avec les formateurs et tous les apprenants."
    },
    {
      question: "Est-ce que j'ai besoin de connaissances en programmation?",
      answer: "Non. Notre approche No-code / Low-code, ainsi que notre support et nos explications détaillées à chaque étape du cours fait que vous n'avez pas besoin de savoir programmer."
    },
    {
      question: "Comment est-ce que j'accède au cours?",
      answer: "Après inscription, vous recevrez un email de confirmation. Cet email de confirmation contiendra toutes les informations d'accès à la plateforme de cours. Le cours est 100% online."
    },
    {
      question: "Y a-t-il une garantie de remboursement?",
      answer: "Oui, nous vous offrons une garantie 100% satisfait ou remboursé, sous 14 jours."
    }
    
  ];

  return (
    <section id="FAQ" className="py-16 bg-[#1C3738]">
      <div className="container mx-auto">
        <h2 className="text-3xl font-bold mb-8 text-center text-[#F4FFF8]">FAQ</h2>
        <div className="space-y-4">
          {faqItems.map((item, index) => (
            <details key={index} className="bg-[#000F08] rounded-lg">
              <summary className="cursor-pointer p-4 text-[#F4FFF8] font-semibold">
                {item.question}
              </summary>
              <div className="p-4 text-[#F4FFF8] bg-[#4D4847]">
                {typeof item.answer === 'string' ? <p>{item.answer}</p> : item.answer}
              </div>
            </details>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQSection;