import React from 'react';
import quentingalleaImage from './images/quentin-gallea.jpg';


const ExpertTestimonialSection = () => {
  return (
    <section className="py-16 bg-[#1C3738]">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center md:items-start space-y-8 md:space-y-0 md:space-x-8">
          {/* Image placeholder */}
          <div className="w-48 h-48 bg-[#4D4847] flex-shrink-0">
            {/* Replace this div with an actual image when available */}
            <div className="w-full h-full bg-[#FFFFFF] flex items-center justify-center text-[#F4FFF8]">
            <img src={quentingalleaImage} alt="Profil de Quentin Gallea" className="w-full h-auto" />
            </div>
          </div>

          {/* Testimonial content */}
          <div className="flex-grow">
            <blockquote className="text-[#F0EBFA] mb-4 italic">
              "Dans l'océan de soi-disant "experts" autoproclamés du jour au lendemain, il est très difficile
              de trouver quelqu'un de fiable sur le sujet de l'IA générative. Heureusement, j'ai rencontré Elliot
              Vaucher, qui a une approche unique et intelligente de l'IA générative, axée sur l'apport de
              valeur aux clients. Son travail sur l'automatisation de la recherche de modèles d'affaires pour
              le capital-risque était remarquable. Il a réussi à aborder ce problème en décomposant
              élégamment le problème en sous-parties et en utilisant plusieurs outils d'IA générative pour
              automatiser la recherche de modèles d'affaires pour un VC. À ce jour, cela reste l'un des
              exemples les plus intelligents que j'ai vus."
            </blockquote>
            <div className="text-[#F0EBFA] font-bold">
              Quentin Gallea PhD, Consultant, spécialiste de l'inférence causale,
            </div>
            <a href="https://www.quentingallea.com/" className="text-[#FFFFFF] hover:underline" target="_blank" rel="noopener noreferrer">
              https://www.quentingallea.com/
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExpertTestimonialSection;